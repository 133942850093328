class NuvetoSurveyMetrics {

    static #options = {
      user_id : '', // ID do usuário
      user_name : '', // Nome do usuário
      system : '', // Nome do sistema
      delay_without_answer : '7', // Tempo em dias para exibir a pesquisa sem resposta
      delay_with_answer : '90', // Tempo em dias para exibir a pesquisa com resposta
      auth_token : '', // Token de autenticação
      lang : {
        title: 'Help us better!', // Título da pesquisa
        commentPH: 'Why you choose this note?', // Placeholder do campo de comentário
        permissionToUse: 'Can we use your evaluation?', // Texto de permissão para uso da avaliação
        permissionToUseButton: 'Yes', // Texto do botão de permissão para uso da avaliação
        sendButton: 'Send feedback', // Texto do botão de envio da avaliação
      }
    }
  
    constructor(options) {
      NuvetoSurveyMetrics.#options = { ...NuvetoSurveyMetrics.#options, ...options};

      if (NuvetoSurveyMetrics.#options.lang.url) {
        this.#getLang();
      }

      this.#lastAnswer()
    }
  
    #buildForm() {
      setTimeout(() => {
        if(document.querySelector('.pop__container')) {
          return;
        }
  
        const popContainer = document.createElement('div');
        popContainer.className = 'pop__container';
        
        const popContent = document.createElement('div');
        popContent.className = 'pop__content';
        
        const popHeader = document.createElement('div');
        popHeader.className = 'pop__header';
        
        const popTitle = document.createElement('p');
        popTitle.className = 'pop__title';
        popTitle.textContent = NuvetoSurveyMetrics.#options.lang.title;
        
        const closeButton = document.createElement('button');
        closeButton.className = 'pop__close';
        closeButton.type = 'button';
        
        const closeIcon = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
        closeIcon.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        closeIcon.setAttribute('width', '16');
        closeIcon.setAttribute('height', '16');
        closeIcon.setAttribute('fill', 'currentColor');
        closeIcon.setAttribute('viewBox', '0 0 16 16');
        
        const closePath = document.createElementNS('http://www.w3.org/2000/svg', 'path');
        closePath.setAttribute('d', 'M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z');
        
        const popBody = document.createElement('div');
        popBody.className = 'pop__body';
        
        const popEvaluation = document.createElement('div');
        popEvaluation.className = 'pop__evaluation';
        
        const popButtons = document.createElement('div');
        popButtons.className = 'pop__buttons';
        
        const popHintEvaluation = document.createElement('div');
        popHintEvaluation.className = 'pop__hint_evaluation';
        
        const popHint1 = document.createElement('p');
        popHint1.className = 'pop__hint pop__emoji';
        popHint1.textContent = '😟';
        
        const popHint2 = document.createElement('p');
        popHint2.className = 'pop__hint pop__emoji';
        popHint2.textContent = '😁';
        
        const popText = document.createElement('div');
        popText.className = 'pop__text';
        
        const evaluationText = document.createElement('textarea');
        evaluationText.name = 'evaluation_text';
        evaluationText.id = 'evaluation_text';
        evaluationText.placeholder = NuvetoSurveyMetrics.#options.lang.commentPH;
        evaluationText.cols = '30';
        evaluationText.rows = '5';
  
        const popMktAction = document.createElement('div');
        popMktAction.className = 'pop__mkt_action';
        
        const popMktHint = document.createElement('p');
        popMktHint.className = 'pop__hint';
        popMktHint.textContent = NuvetoSurveyMetrics.#options.lang.permissionToUse;
        
        const popCheckbox = document.createElement('div');
        popCheckbox.className = 'pop__checkbox';
        
        const checkbox = document.createElement('input');
        checkbox.type = 'checkbox';
        checkbox.setAttribute('checked', 'true');
        checkbox.style.marginRight = '5px';
        checkbox.id = 'pop_confirm';
        
        const checkboxLabel = document.createElement('label');
        checkboxLabel.className = 'pop__hint';
        checkboxLabel.setAttribute('for', 'pop_confirm');
        checkboxLabel.textContent = NuvetoSurveyMetrics.#options.lang.permissionToUseButton;
        
        const popFooter = document.createElement('div');
        popFooter.className = 'pop__footer';
        
        const sendButton = document.createElement('button');
        sendButton.type = 'button';
        sendButton.className = 'pop__send';
        sendButton.setAttribute('onclick', "window.NuvetoSurveyMetrics.selectOption('sendSurvey')");
        sendButton.textContent = NuvetoSurveyMetrics.#options.lang.sendButton;
        
        closeButton.appendChild(closeIcon);
        closeIcon.appendChild(closePath);
        
        popHeader.appendChild(popTitle);
        popHeader.appendChild(closeButton);
        
        popButtons.appendChild(popHint1);
        popButtons.appendChild(popHint2);
        
        popHintEvaluation.appendChild(popHint1);
        popHintEvaluation.appendChild(popHint2);
        
        popEvaluation.appendChild(popButtons);
        popEvaluation.appendChild(popHintEvaluation);
        
        popCheckbox.appendChild(checkbox);
        popCheckbox.appendChild(checkboxLabel);
        
        popMktAction.appendChild(popMktHint);
        popMktAction.appendChild(popCheckbox);
        
        popText.appendChild(evaluationText);
        popText.appendChild(popMktAction);
        
        popBody.appendChild(popEvaluation);
        popBody.appendChild(popText);
        
        popFooter.appendChild(sendButton);
        
        popContent.appendChild(popHeader);
        popContent.appendChild(popBody);
        popContent.appendChild(popFooter);
        
        popContainer.appendChild(popContent);
        
        // Adiciona o elemento à página
        const body = document.querySelector('body');
        body.appendChild(popContainer);
        
        const getButtons = document.querySelector('.pop__buttons')
        
        for (let i = 0; i <= 10; i++) {
          getButtons.innerHTML += `
            <button class="pop__button_question" type="button" id="pop__button-${i}" onclick="window.NuvetoSurveyMetrics.selectOption(${i})" >
              ${i}
            </button>
          `;
        }
        
        const getAllButtons = document.querySelectorAll('.pop__buttons button');
        
        getAllButtons.forEach(button => {
          button.addEventListener('click', function() {
            const popActive = button.classList.contains('pop__active');
        
            if (!popActive) {
              const activeButton = document.querySelector('.pop__buttons button.pop__active');
              if (activeButton) {
                activeButton.classList.remove('pop__active');
              }
              button.classList.add('pop__active');
            }
          });
        });
        
        setTimeout(() => {
          popContent.classList.add('pop__background');
        }, 1000);
        
        getAllButtons.forEach(button => {
          button.addEventListener('click', function() {
            popContent.style.height = '21rem';
          });
        });
        
        const popClose = document.querySelector('.pop__close');
        
        popClose.addEventListener('click', function() {
          popContent.style.height = '0px';
          window.NuvetoSurveyMetrics.selectOption()
        });
      }, 1000);
    }
  
    static async selectOption(index = null) {
  
      const getAllButtons = document.querySelectorAll('.pop__buttons button');
  
      for (let i = 0; i <= 10; i++) {
        setTimeout(() => {
          if(getAllButtons[i].classList.value == "pop__active") {
          } else {
            getAllButtons[i].disabled = true;
            getAllButtons[i].classList.add('disabled__button');
            setTimeout(() => {getAllButtons[i].disabled = false; getAllButtons[i].classList.remove('disabled__button');}, 1000);
          }
        } , 100);
      }
    
      let body = {
        'user_id' : NuvetoSurveyMetrics.#options.user_id,
        'user_name' : NuvetoSurveyMetrics.#options.user_name,
        'system' : NuvetoSurveyMetrics.#options.system,
        'delay_without_answer' : NuvetoSurveyMetrics.#options.delay_without_answer,
        'delay_with_answer' : NuvetoSurveyMetrics.#options.delay_with_answer
      };
    
      if(index == 'sendSurvey') {
        var popContent = document.querySelector('.pop__content');
        popContent.style.height = '0px';
        let text = document.querySelector("#evaluation_text").value;
        let usable = document.querySelector('#pop_confirm').checked;
        body.survey_comment = text;
        body.usable = usable;
      } else {
        body.survey_answer = index;
      }
    
      let response = await fetch('https://surveymetrics.nuvetoapps.com.br/api/surveyAnswer', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": 'Bearer ' + NuvetoSurveyMetrics.#options.auth_token,
        },
        body: JSON.stringify(body),
      });
  
      response = await response.json();
      sessionStorage.setItem('NuvetoSurveyMetrics', JSON.stringify(response));
    }
  
    async #lastAnswer() {
      let response = null;
      
      if(sessionStorage) {
        response = JSON.parse(sessionStorage.getItem('NuvetoSurveyMetrics'));
      }

      if(!response) {
        response = await fetch('https://surveymetrics.nuvetoapps.com.br/api/LastAnswer?user_id=' + NuvetoSurveyMetrics.#options.user_id + '&system=' + NuvetoSurveyMetrics.#options.system, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + NuvetoSurveyMetrics.#options.auth_token
          }
        });

        if(response.status != 200) {
          return;
        }
        response = await response.json();
        sessionStorage.setItem('NuvetoSurveyMetrics', JSON.stringify(response));
      }

      if(response.data == "no metrics") {
        response.data = {};
        sessionStorage.setItem('NuvetoSurveyMetrics', JSON.stringify(response));
        this.#buildForm();
        return;
      }

      if(response.data.survey_answer) {
        if(response.data.diff_days >= NuvetoSurveyMetrics.#options.delay_with_answer) {
          this.#buildForm();
        }
      } else {
        if(response.data.diff_days >= NuvetoSurveyMetrics.#options.delay_without_answer ) {
          this.#buildForm();
        }
      }
    } 
  
    static destroy() {
      const popContent = document.querySelector('.pop__content');
      if(popContent) {
        popContent.style.height = '0px';
      }
    }

    async #getLang() {
      let resposta = await fetch('https://surveymetrics.nuvetoapps.com.br/dist/lang/' + NuvetoSurveyMetrics.#options.lang.url + '.json', {
          method: "GET",
      });

      if(resposta.status == 200) {
          resposta = await resposta.json();
          NuvetoSurveyMetrics.#options.lang = { ...resposta, ...NuvetoSurveyMetrics.#options.lang };
      }
    }
}
  
window.NuvetoSurveyMetrics = NuvetoSurveyMetrics;